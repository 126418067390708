// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DistrictName, DistrictNameSchema } from 'api/gen/DistrictName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  allAnimalsPlanned: t.boolean,
  anyAnimalMarked: t.boolean,
  arealRating: t.boolean,
  breederName: BreederNameSchema,
  breedingNumber: BreedingNumberSchema,
  districtName: DistrictNameSchema,
  id: PositiveLongSchema,
  maxDaysSinceCalveDate: PositiveIntegerSchema,
  maxDaysSinceCalveDateOfMarked: t.union([PositiveIntegerSchema, t.null]),
  numOfMeasurableAnimals: PositiveOrZeroLongSchema,
  numOfPlannedAnimals: PositiveOrZeroLongSchema,
  stableName: StableNameSchema,
});

export const PlanningBreedingSchema = excess(requiredPart);

export interface PlanningBreeding extends t.TypeOf<typeof PlanningBreedingSchema> {}
