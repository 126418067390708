import {ActionSettings, TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {simpleBreederSelector} from 'app/breeder/model';
import {useOurTranslation} from 'app/translations';
import {ConnectedTable} from 'utils/tables';

import {getColumns} from './columns';

interface Props {
    getBreeders(tableDataRequest: Opt<TableDataRequest<Breeder>>): void;
    onGoTo(path: string): void;
}

const BreederTableBase: FC<Props> = props => {
    const {
        getBreeders,
        onGoTo,
    } = props;

    const {t, tCommon} = useOurTranslation('breeder');

    const goToDetail = useCallback((breedingId: number) => {
        onGoTo(`/breeder-detail/${breedingId}`);
    }, [onGoTo]);

    const actionSettings = useMemo((): ActionSettings<Breeder> => ({
        onEdit: goToDetail,
    }), [goToDetail]);

    return (
        <ConnectedTable
            tableId="breeder"
            columns={getColumns(t, tCommon)}
            rowDataPageSelector={simpleBreederSelector.breederPage}
            onFetchTableData={getBreeders}
            actionSettings={actionSettings}
            pathnamesForRefresh={[/^\/breeder-detail\/\d+$/]}
            rowActionsOnRight
            highlightOnEdit
            enableTableUserSettings
            showFulltextFilter
        />
    );
};

export const BreederTable = memo(BreederTableBase);
