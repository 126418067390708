import {none, Opt} from 'ts-opt';

import {BasicBreeding} from 'api/gen/BasicBreeding';
import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagedModelPlanningAnimal} from 'api/gen/PagedModelPlanningAnimal';
import {PagedModelPlanningBreeding} from 'api/gen/PagedModelPlanningBreeding';

export interface BreedingState {
    breedingPage: Opt<PagedModelPlanningBreeding>;
    breeding: BreedingDetails | null;
    animalsInBreedingPage: Opt<PagedModelPlanningAnimal>;
    breedingNumbers: Array<BasicBreeding> | null;
    selectedAnimalsInBreeding: Array<number>;
    fetchingIds: Array<number>;
    breedingFetchingIds: Array<number>;
}

export const initialBreedingState: BreedingState = {
    breedingPage: none,
    breeding: null,
    animalsInBreedingPage: none,
    breedingNumbers: null,
    selectedAnimalsInBreeding: [],
    fetchingIds: [],
    breedingFetchingIds: [],
};
