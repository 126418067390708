import {takeLatestF, putAll, showBeError, setAuthData, deleteAuthData} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {put, select, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {homePagePath} from 'app/consts';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {simpleLayoutSelector} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {loginFormInitialValue, LoginFormValues, LoginFormValuesSchema} from '../../types/login-form-values';
import {userAction, LogInUserAction} from '../action';

const getLoginFormValues = (state: State): LoginFormValues =>
    formHelpers.formValues('login')(state).narrow(LoginFormValuesSchema.is).orElse(loginFormInitialValue);

function* execute(_: LogInUserAction): SagaIterator {
    yield* put(formHelpers.startSubmit('login'));

    const loginValues = yield* select(getLoginFormValues);

    setAuthData(loginValues);

    const state = yield* select(simpleLayoutSelector.locationState);
    const response = yield* call(Api.getUserDetails);

    if (response.isSuccess) {
        yield* put(userAction.currentUserLoaded(response.data));
        yield* put(formHelpers.reset('login'));
        const {redirectTo} = state || {};
        yield* put(routerActions.push(redirectTo && redirectTo !== '/logout' ? redirectTo : homePagePath));
    } else {
        if (response.isUnauthorized) {
            deleteAuthData();
        }
        yield putAll(showBeError(response, t('user/messages')('logInTitle'), {isLogin: true}));
    }

    yield* put(formHelpers.stopSubmit('login', {}));
    yield* put(userAction.initializeFinish());
}

export function* logInUserSaga(): SagaIterator {
    yield takeLatestF('user/LOG_IN_USER', execute);
}
