import {none, Opt} from 'ts-opt';

import {EmailTemplate} from 'api/gen/EmailTemplate';
import {PagedModelBreederSuite} from 'api/gen/PagedModelBreederSuite';

import {BreederSuiteRow} from '../components/breeder-suites-table/columns';

export interface BreederSuiteState {
    breederSuitePage: Opt<PagedModelBreederSuite>;
    selectedBreederSuitesIds: Array<string>;
    selectedBreederSuites: Array<BreederSuiteRow>;
    emailTemplate: Opt<EmailTemplate>;
}

export const initialBreederSuiteState: BreederSuiteState = {
    breederSuitePage: none,
    selectedBreederSuitesIds: [],
    selectedBreederSuites: [],
    emailTemplate: none,
};
