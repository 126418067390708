import React, {FC, memo, useCallback} from 'react';
import {flatMap} from 'ts-opt';

import {BreederInPlan} from 'api/gen/BreederInPlan';
import {StableInPlan} from 'api/gen/StableInPlan';
import {gutter} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {Button} from 'common/buttons';
import {Card} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import {PlanAnimalsTable} from '../plan-animals-table';

import styles from './styles.sass';

interface Props {
    breeder: BreederInPlan;
    selectedAnimals: Array<number>;
    planEditable: boolean;
    visibleStables: Array<number>;
    loadingItems: Array<LoadingItemId>;
    testId: string;
    onToggleSelectAnimal(animalId: number, breederId: number, animalsInCompany: Array<number>): void;
    onOpenPlanStableEmailModal(stableId: number): void;
    onOpenPlanBreederEmailModal(breederId: number): void;
    onRemoveBreeder(animalIds: Array<number>): void;
    onPrintBreederPlan(breederId: number): void;
    onPrintBreederStablePlan(stableId: number): void;
    onToggleCollapse(id: number): void;
}

const getAnimalsIdOfOneStable = (stable: StableInPlan): Array<number> =>
    stable.animals.map(y => y.id);

const getAnimalsIdOfCompany = flatMap(getAnimalsIdOfOneStable);

const PlanBreederSectionBase: FC<Props> = props => {
    const {
        breeder,
        selectedAnimals,
        planEditable,
        visibleStables,
        loadingItems,
        onOpenPlanStableEmailModal,
        onOpenPlanBreederEmailModal,
        onRemoveBreeder,
        onPrintBreederStablePlan,
        onToggleCollapse,
        onToggleSelectAnimal,
        onPrintBreederPlan,
        testId,
    } = props;

    const {t} = useOurTranslation('planning/planBreeder');

    const handleToggleSelectAnimal = useCallback((animalId: number) => {
        const {id, stables} = breeder;
        onToggleSelectAnimal(animalId, id, getAnimalsIdOfCompany(stables));
    }, [breeder, onToggleSelectAnimal]);

    const handlePrintBreederPlan = useCallback(() => {
        onPrintBreederPlan(breeder.id);
    }, [breeder.id, onPrintBreederPlan]);

    const handleSendToBreeder = useCallback(() => {
        onOpenPlanBreederEmailModal(breeder.id);
    }, [breeder.id, onOpenPlanBreederEmailModal]);

    const emailSending = loadingItems.includes('sendPlanStableEmail') || loadingItems.includes('sendPlanBreederEmail');

    return (
        <Card
            title={`${t('breeder')} ${breeder.name}`}
            extra={(
                <div className={styles.actionsRow}>
                    <Ant.Row
                        gutter={[gutter, gutter]}
                        justify="end"
                    >
                        <Ant.Col
                            xs={24}
                            sm={12}
                            md={8}
                        >
                            <Button
                                visuals="primary"
                                disabled={emailSending}
                                loading={loadingItems.includes('sendPlanBreederEmail')}
                                onClick={handleSendToBreeder}
                                icon="mailOutlined"
                                block
                                testId="send-breeder-button"
                            >
                                {t('sendToBreeder')}
                            </Button>
                        </Ant.Col>
                        <Ant.Col
                            xs={24}
                            sm={12}
                            md={8}
                        >
                            <Button
                                visuals="primary"
                                disabled={emailSending}
                                loading={loadingItems.includes('printBreeder')}
                                onClick={handlePrintBreederPlan}
                                icon="printerOutlined"
                                block
                            >
                                {t('printBreeder')}
                            </Button>
                        </Ant.Col>
                    </Ant.Row>
                </div>
            )}
            noPaddingTop
            marginBottom
            smallSidePadding
            testId={testId}
        >
            {breeder.stables.map(stable => (
                <PlanAnimalsTable
                    stable={stable}
                    selectedAnimals={selectedAnimals}
                    planEditable={planEditable}
                    visibleStables={visibleStables}
                    loadingItems={loadingItems}
                    onToggleSelectAnimal={handleToggleSelectAnimal}
                    onOpenPlanEmailModal={onOpenPlanStableEmailModal}
                    onRemoveBreeder={onRemoveBreeder}
                    onPrintBreederStablePlan={onPrintBreederStablePlan}
                    onToggleCollapse={onToggleCollapse}
                    key={stable.name}
                />
            ))}
        </Card>
    );
};

export const PlanBreederSection = memo(PlanBreederSectionBase);
