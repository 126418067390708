import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {breederAction, GetStablesAlternativeEmailsAction} from '../action';

function* execute(action: GetStablesAlternativeEmailsAction): SagaIterator {
    const {stableIds} = action.payload;

    yield* put(layoutAction.setItemLoading('stableEmails', true));
    const response = yield* call(Api.findAllEmailsByStableIds, {stableIds});

    if (response.isSuccess) {
        yield* put(breederAction.getStablesAlternativeEmailsSuccess(response.data));
    } else {
        yield* put(breederAction.getStablesAlternativeEmailsSuccess([]));
        yield putAll(showBeError(response, t('breeder')('getStablesAlternativeEmails')));
    }

    yield* put(layoutAction.setItemLoading('stableEmails', false));
}

export function* getStablesAlternativeEmailsSaga(): SagaIterator {
    yield takeLatestF('breeder/GET_STABLES_ALTERNATIVE_EMAILS', execute);
}
