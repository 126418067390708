import {none, Opt} from 'ts-opt';

import {PagedModelAbstractMeasurementView} from 'api/gen/PagedModelAbstractMeasurementView';

export interface DatabaseOfMeasuredState {
    measurementsPage: Opt<PagedModelAbstractMeasurementView>;
}

export const initialDatabaseOfMeasuredState: DatabaseOfMeasuredState = {
    measurementsPage: none,
};
