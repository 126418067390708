import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {MeasurementView} from 'api/gen/MeasurementView';
import {PagedModelAbstractMeasurementView} from 'api/gen/PagedModelAbstractMeasurementView';

export const databaseOfMeasuredAction = {
    getMeasurements: (tableDataRequest: Opt<TableDataRequest<MeasurementView>>) => ({
        type: 'databaseOfMeasured/GET_MEASUREMENTS',
        payload: {tableDataRequest},
    }) as const,
    getMeasurementsSuccess: (measurementsPage: Opt<PagedModelAbstractMeasurementView>) => ({
        type: 'databaseOfMeasured/GET_MEASUREMENTS_SUCCESS',
        payload: {measurementsPage},
    }) as const,
    exportMeasured: () => ({
        type: 'databaseOfMeasured/EXPORT_MEASURED',
    }) as const,
};

export type GetMeasurementsAction = ReturnType<typeof databaseOfMeasuredAction.getMeasurements>;
export type GetMeasurementsSuccessAction = ReturnType<typeof databaseOfMeasuredAction.getMeasurementsSuccess>;
export type ExportMeasuredAction = ReturnType<typeof databaseOfMeasuredAction.exportMeasured>;

export type DatabaseOfMeasuredAction =
    | GetMeasurementsAction
    | GetMeasurementsSuccessAction
    | ExportMeasuredAction
    ;
