import {none, Opt} from 'ts-opt';

import {PagedModelAbstractMeasurementAnimal} from 'api/gen/PagedModelAbstractMeasurementAnimal';
import {PagedModelRatingToApprove} from 'api/gen/PagedModelRatingToApprove';
import {RatingToApproveStatistics} from 'api/gen/RatingToApproveStatistics';

export interface MeasurementState {
    allRatings: Opt<PagedModelRatingToApprove>;
    ratingsOfAnimal: Opt<PagedModelAbstractMeasurementAnimal>;
    foundEarTags: Array<string> | null;
    ratingInEdit: Opt<number>;
    editedRating: Opt<number>;
    stats: Opt<RatingToApproveStatistics>;
}

export const initialMeasurementState: MeasurementState = {
    allRatings: none,
    ratingsOfAnimal: none,
    foundEarTags: null,
    ratingInEdit: none,
    editedRating: none,
    stats: none,
};
