import {Options} from '@fl/cmsch-fe-library';
import {createSelector, Selector} from 'reselect';
import {Opt} from 'ts-opt';

import {PagedModelAbstractMeasurementAnimal} from 'api/gen/PagedModelAbstractMeasurementAnimal';
import {PagedModelRatingToApprove} from 'api/gen/PagedModelRatingToApprove';
import {RatingToApprove} from 'api/gen/RatingToApprove';
import {RatingToApproveStatistics} from 'api/gen/RatingToApproveStatistics';
import {State} from 'app/setup';

export const simpleMeasurementSelector = {
    ratingsOfAnimalPage: ({measurements}: State): Opt<PagedModelAbstractMeasurementAnimal> =>
        measurements.ratingsOfAnimal,
    ratingInEditId: ({measurements}: State): Opt<number> => measurements.ratingInEdit,
    ratingInEdit: ({measurements}: State): Opt<RatingToApprove> => measurements.allRatings
        .prop('content').findIn(rating => measurements.ratingInEdit.contains(rating.id)),
    ratingsCount: ({measurements}: State): Opt<number> => measurements.allRatings.prop('page').prop('totalElements'),
    ratingStats: ({measurements}: State): Opt<RatingToApproveStatistics> => measurements.stats,
    foundEarTags: ({measurements}: State): Array<string> | null => measurements.foundEarTags,
    editedRating: ({measurements}: State): number | undefined => measurements.editedRating.orUndef(),
    allRatings: ({measurements}: State): Opt<PagedModelRatingToApprove> => measurements.allRatings,
};

export const foundEarTagsSelector = createSelector<[Selector<State, Array<string> | null>], Options<string>>(
    simpleMeasurementSelector.foundEarTags,
    (foundEarTags: Array<string> | null): Options<string> => (foundEarTags || [])
        .map(earTag => ({value: earTag, label: earTag})),
);
