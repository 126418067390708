/* eslint-disable import/no-unused-modules */
import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {MeasurementAnimal} from 'api/gen/MeasurementAnimal';
import {PagedModelAbstractMeasurementAnimal} from 'api/gen/PagedModelAbstractMeasurementAnimal';
import {PagedModelRatingToApprove} from 'api/gen/PagedModelRatingToApprove';
import {RatingToApprove} from 'api/gen/RatingToApprove';
import {RatingToApproveStatistics} from 'api/gen/RatingToApproveStatistics';
import {RatingUpdate} from 'api/gen/RatingUpdate';

export const measurementAction = {

    getMeasurements: (tableDataRequest: Opt<TableDataRequest<RatingToApprove>>) => ({
        type: 'measurement/GET_MEASUREMENTS',
        payload: {tableDataRequest},
    }) as const,
    getMeasurementsSuccess: (ratingsOfAnimal: Opt<PagedModelRatingToApprove>) => ({
        type: 'measurement/GET_MEASUREMENTS_SUCCESS',
        payload: {ratingsOfAnimal},
    }) as const,
    getMeasurementsStats: (tableDataRequest: Opt<TableDataRequest<RatingToApprove>>) => ({
        type: 'measurement/GET_MEASUREMENTS_STATS',
        payload: {tableDataRequest},
    }) as const,
    getMeasurementsStatsSuccess: (ratingStats: Opt<RatingToApproveStatistics>) => ({
        type: 'measurement/GET_MEASUREMENTS_STATS_SUCCESS',
        payload: {ratingStats},
    }) as const,
    clearMeasurementDetail: () => ({
        type: 'measurement/CLEAR_MEASUREMENT_DETAIL',
    }) as const,
    approveMeasurements: () => ({
        type: 'measurement/APPROVE_MEASUREMENTS',
    }) as const,
    deleteMeasurement: (ratingId: number) => ({
        type: 'measurement/DELETE_MEASUREMENT',
        payload: {ratingId},
    }) as const,
    mortifyMeasurement: (ratingId: number) => ({
        type: 'measurement/MORTIFY_MEASUREMENT',
        payload: {ratingId},
    }) as const,
    getRatingsOfAnimal: (tableDataRequest: Opt<TableDataRequest<MeasurementAnimal>>, animalId: number) => ({
        type: 'measurement/GET_RATINGS_OF_ANIMAL',
        payload: {tableDataRequest, animalId},
    }) as const,
    getRatingsOfAnimalSuccess: (ratingsOfAnimal: Opt<PagedModelAbstractMeasurementAnimal>) => ({
        type: 'measurement/GET_RATINGS_OF_ANIMAL_SUCCESS',
        payload: {ratingsOfAnimal},
    }) as const,
    assignRating: () => ({
        type: 'measurement/ASSIGN_RATING',
    }) as const,
    findEarTags: (value: string) => ({
        type: 'measurement/FIND_EARTAGS',
        payload: {value},
    }) as const,
    findEarTagsSuccess: (earTags: Array<string> | null) => ({
        type: 'measurement/FIND_EARTAGS_SUCCESS',
        payload: {earTags},
    }) as const,
    updateRating: (rating: RatingUpdate) => ({
        type: 'measurement/UPDATE_RATING',
        payload: {rating},
    }) as const,
    setRatingInEdit: (ratingId?: number) => ({
        type: 'measurement/SET_RATING_IN_EDIT',
        payload: {ratingId},
    }) as const,
    setEditedRating: (ratingId: Opt<number>) => ({
        type: 'measurement/SET_EDITED_RATING',
        payload: {ratingId},
    }) as const,
};

export type GetMeasurementsAction = ReturnType<typeof measurementAction.getMeasurements>;
export type GetMeasurementsSuccessAction = ReturnType<typeof measurementAction.getMeasurementsSuccess>;
export type ClearMeasurementDetailAction = ReturnType<typeof measurementAction.clearMeasurementDetail>;
export type ApproveMeasurementsAction = ReturnType<typeof measurementAction.approveMeasurements>;
export type DeleteMeasurementAction = ReturnType<typeof measurementAction.deleteMeasurement>;
export type MortifyMeasurementAction = ReturnType<typeof measurementAction.mortifyMeasurement>;
export type GetRatingsOfAnimalAction = ReturnType<typeof measurementAction.getRatingsOfAnimal>;
export type GetRatingsOfAnimalSuccessAction = ReturnType<typeof measurementAction.getRatingsOfAnimalSuccess>;
export type AssignRatingAction = ReturnType<typeof measurementAction.assignRating>;
export type FindEarTagsAction = ReturnType<typeof measurementAction.findEarTags>;
export type FindEarTagsSuccessAction = ReturnType<typeof measurementAction.findEarTagsSuccess>;
export type UpdateRatingAction = ReturnType<typeof measurementAction.updateRating>;
export type SetRatingInEditAction = ReturnType<typeof measurementAction.setRatingInEdit>;
export type SetEditedRatingAction = ReturnType<typeof measurementAction.setEditedRating>;
export type GetMeasurementsStatsAction = ReturnType<typeof measurementAction.getMeasurementsStats>;
export type GetMeasurementsStatsSuccessAction = ReturnType<typeof measurementAction.getMeasurementsStatsSuccess>;

export type MeasurementsAction =
    | GetMeasurementsAction
    | GetMeasurementsSuccessAction
    | ClearMeasurementDetailAction
    | ApproveMeasurementsAction
    | DeleteMeasurementAction
    | MortifyMeasurementAction
    | GetRatingsOfAnimalAction
    | GetRatingsOfAnimalSuccessAction
    | AssignRatingAction
    | FindEarTagsAction
    | FindEarTagsSuccessAction
    | UpdateRatingAction
    | SetRatingInEditAction
    | SetEditedRatingAction
    | GetMeasurementsStatsAction
    | GetMeasurementsStatsSuccessAction
    ;
