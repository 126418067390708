import {ActionSettings, TableDataRequest, Nullable, ToolbarButton} from '@fl/cmsch-fe-library';
import {isEmpty, isUndefined} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {BreederSuite} from 'api/gen/BreederSuite';
import {UserDetails} from 'api/gen/UserDetails';
import {useOurTranslation} from 'app/translations';
import {ConnectedTable} from 'utils/tables';

import {simpleBreederSuiteSelector} from '../../model';
import {BreederSuiteRow, getColumns} from './columns';
import {rowMapper} from './row-mapper';

interface Props {
    selectedIds: Array<string>;
    selectedRows: Array<BreederSuiteRow>;
    breederSuitesExportingExcel: boolean;
    breederSuitesExportingPdf: boolean;
    mailSending: boolean;
    currentUser: Nullable<UserDetails>;
    getBreederSuites(tableDataRequest: Opt<TableDataRequest<BreederSuite>>): void;
    toggleSelect(ids: Array<string>, rows: Array<BreederSuite>): void;
    exportBreederSuitesToExcel(ids: Array<string>): void;
    exportBreederSuitesToPdf(ids: Array<string>): void;
    sendMailToBreeders(ids: Array<string>): void;
}

const BreederSuitesTableBase: FC<Props> = props => {
    const {
        selectedIds,
        selectedRows,
        breederSuitesExportingExcel,
        breederSuitesExportingPdf,
        mailSending,
        currentUser,
        getBreederSuites,
        toggleSelect,
        exportBreederSuitesToExcel,
        exportBreederSuitesToPdf,
        sendMailToBreeders,
    } = props;

    const {t, tCommon} = useOurTranslation('breederSuite/BreederSuitesTable');
    const {tenant} = currentUser || {};

    const actionSettings: ActionSettings<BreederSuiteRow> = useMemo(() => ({
        selectedIds,
        selectedRows,
        onSelectedIdsChange: toggleSelect,
    }), [selectedIds, selectedRows, toggleSelect]);

    const handleExportToExcel = useCallback(() => {
        exportBreederSuitesToExcel(selectedIds);
    }, [exportBreederSuitesToExcel, selectedIds]);

    const handleExportToPdf = useCallback(() => {
        exportBreederSuitesToPdf(selectedIds);
    }, [exportBreederSuitesToPdf, selectedIds]);

    const handleSendMail = useCallback(() => {
        sendMailToBreeders(selectedIds);
    }, [sendMailToBreeders, selectedIds]);

    const headerButtons = useMemo((): Array<ToolbarButton> => [
        {
            title: t('downloadExportPdf'),
            icon: 'downloadOutlined',
            visuals: 'primary',
            loading: breederSuitesExportingPdf,
            disabled: isEmpty(selectedIds),
            disabledTitle: tCommon('chooseAtLeastOne'),
            onClick: handleExportToPdf,
            testId: 'export-pdf',
        },
        {
            title: t('downloadExportExcel'),
            icon: 'downloadOutlined',
            visuals: 'primary',
            loading: breederSuitesExportingExcel,
            disabled: isEmpty(selectedIds),
            disabledTitle: tCommon('chooseAtLeastOne'),
            onClick: handleExportToExcel,
            testId: 'export-excel',
        },
        {
            title: t('sendToBreeders', {count: selectedIds.length}),
            icon: 'mailOutlined',
            visuals: 'primary',
            loading: mailSending,
            disabled: isEmpty(selectedIds),
            disabledTitle: tCommon('chooseAtLeastOne'),
            onClick: handleSendMail,
            testId: 'send-to-breeders',
        },
    ], [
        breederSuitesExportingExcel,
        breederSuitesExportingPdf,
        handleExportToExcel,
        handleExportToPdf,
        handleSendMail,
        mailSending,
        selectedIds,
        t,
        tCommon,
    ]);

    if (isUndefined(tenant)) return null;

    return tenant === 'HOLSTEIN' ? (
        <ConnectedTable
            tableId="breederSuitesHolstein"
            columns={getColumns(t, tCommon)}
            rowDataToTableRowMapper={rowMapper}
            rowDataPageSelector={simpleBreederSuiteSelector.breederSuitePage}
            onFetchTableData={getBreederSuites}
            actionSettings={actionSettings}
            headerButtons={headerButtons}
            withoutVerticalScrollbar
            enableTableUserSettings
        />
    ) : (
        <ConnectedTable
            tableId="breederSuitesFleckvieh"
            columns={getColumns(t, tCommon)}
            rowDataToTableRowMapper={rowMapper}
            rowDataPageSelector={simpleBreederSuiteSelector.breederSuitePage}
            onFetchTableData={getBreederSuites}
            actionSettings={actionSettings}
            headerButtons={headerButtons}
            withoutVerticalScrollbar
            enableTableUserSettings
        />
    );
};

export const BreederSuitesTable = memo(BreederSuitesTableBase);
