import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {measurementAction} from 'app/measurement/model/action';
import {State} from 'app/setup/state';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout/model/action';
import {formHelpers} from 'utils/forms';

import {AssignBreedingToRatingAction, unknownAnimalAction} from '../action';
import {simpleUnknownAnimalSelector} from '../selector';

const selectBreedingId = (state: State): number =>
    formHelpers.formValues('selectBreeding')(state).propOrCrash('breedingId');

function* execute(_: AssignBreedingToRatingAction): SagaIterator {
    yield* put(formHelpers.startSubmit('selectBreeding'));

    const breedingId = yield* select(selectBreedingId);
    const animalInEdit = yield* select(simpleUnknownAnimalSelector.animalInEdit);
    const ratingId = animalInEdit.prop('id').orCrash('missing animalInEdit');
    const response = yield* call(Api.assignBreedingToRating, {breedingId}, {ratingId});

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('selectBreeding'));
        yield* put(layoutAction.setModalVisibility('assignToBreeding', false));
        yield* put(formHelpers.reset('selectBreeding'));
        yield* put(unknownAnimalAction.resetAnimalToAssign());
        yield* put(unknownAnimalAction.getUnknownAnimals());
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
        yield* put(measurementAction.setEditedRating(opt(ratingId)));
    } else {
        yield* put(formHelpers.stopSubmit('selectBreeding'));
        yield putAll(showBeError(response, t('unknownAnimals')('assignAnimalToBreeding')));
    }
}

export function* assignBreedingToRatingSaga(): SagaIterator {
    yield takeLatestF('unknownAnimals/ASSIGN_BREEDING_TO_RATING', execute);
}
