import {TableColumn, Options} from '@fl/cmsch-fe-library';

import {PlanningBreeding} from 'api/gen/PlanningBreeding';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    districtOptions: Options<string>,
    t: TFunction<'breeding/BreedingTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<PlanningBreeding>> => [
    {
        field: 'districtName',
        column: t('header.districtNames'),
        type: 'reactNode',
        options: districtOptions,
        filterTypes: ['multiSelectString'],
        sortable: true,
        width: 412,
        fulltextFilterEnabled: true,
    },
    {
        field: 'breederName',
        column: tCommon('company'),
        type: 'string',
        sortable: true,
        width: 412,
        fulltextFilterEnabled: true,
    },
    {
        field: 'stableName',
        column: tCommon('stable'),
        type: 'string',
        sortable: true,
        width: 412,
        fulltextFilterEnabled: true,
    },
    {
        field: 'breedingNumber',
        column: tCommon('breedingNumber'),
        type: 'string',
        sortable: true,
        width: 140,
        fulltextFilterEnabled: true,
    },
    {
        field: 'numOfPlannedAnimals',
        column: t('header.numOfPlannedAnimals'),
        type: 'number',
        sortable: true,
        width: 165,
    },
    {
        field: 'numOfMeasurableAnimals',
        column: t('header.numOfMeasurableAnimals'),
        type: 'number',
        sortable: true,
        width: 165,
    },
    {
        field: 'anyAnimalMarked',
        column: t('header.anyAnimalMarked'),
        type: 'boolean',
        formatValue: (value: boolean): string => value ? 'T' : '',
        filterLabels: [t('anyAnimalMarkedFilterLabel')],
        sortable: true,
        width: 60,
    },
    {
        field: 'arealRating',
        column: t('header.arealRating'),
        type: 'boolean',
        formatValue: (value: boolean): string => value ? 'P' : '',
        filterLabels: [t('arealRatingFilterLabel')],
        sortable: true,
        width: 60,
    },
];
