import {ActionSettings, TableRowBackground, TableDataRequest, Options} from '@fl/cmsch-fe-library';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {AnimalInPlanType} from 'api/gen/AnimalInPlanType';
import {RatingToApprove} from 'api/gen/RatingToApprove';
import {measurementAction, simpleMeasurementSelector} from 'app/measurement/model';
import {useOurTranslation, TFunction} from 'app/translations';
import {isDuplicateAnimal} from 'utils/is-duplicate-animal';
import {ConnectedTable} from 'utils/tables';

import {getColumns} from './columns';

const getTypeOptions = (t: TFunction<'measurement/table'>): Options<AnimalInPlanType> => [
    {value: 'FROM_MOBILE', label: t('typeOptions.isNewMobile')},
    {value: 'FROM_WEB', label: t('typeOptions.isNewWeb')},
    {value: 'FROM_PLANNED_STABLE', label: t('typeOptions.outsidePlan')},
    {value: 'PLANNED', label: t('typeOptions.inPlan')},
];

interface Props {
    mortifyInProgress: boolean;
    deleteRating(ratingId: number): void;
    mortifyAnimal(ratingId: number): void;
    editRating(ratingId: number): void;
}

const MeasurementsTableBase: FC<Props> = props => {
    const {mortifyInProgress, deleteRating, mortifyAnimal, editRating} = props;
    const {t, tCommon} = useOurTranslation('measurement/table');
    const dispatch = useDispatch();

    const ratings = useSelector(simpleMeasurementSelector.allRatings);

    const getMeasurements = useCallback((tableDataRequest: Opt<TableDataRequest<RatingToApprove>>) => {
        dispatch(measurementAction.getMeasurements(tableDataRequest));
    }, [dispatch]);

    const calcRowBackgroundDesign = useCallback(
        (row: RatingToApprove, _index: number): TableRowBackground | undefined =>
            isDuplicateAnimal(row, ratings.prop('content').orElse([]))
            || row.sumOfApprovedMeasurements > 0 ? 'dangerous' : undefined
        , [ratings]);

    const actionSettings: ActionSettings<RatingToApprove> = useMemo(() => ({
        onEdit: editRating,
        onRowRemove: deleteRating,
        confirmRowRemove: true,
        extraActions: [
            {
                id: 'mortify',
                icon: 'closeOutlined',
                title: (_rowId: string | number, row: RatingToApprove): string =>
                    t(row.dead ? 'animalIsDead' : 'mortifyAnimal'),
                callback: mortifyAnimal,
                confirmClick: true,
                disabled: (_rowId: string | number, row: RatingToApprove): boolean => row.dead || mortifyInProgress,
                testId: 'mortify-animal-button',
            },
            {
                id: 'detail',
                icon: 'searchOutlined',
                href: (_rowId: string | number, row: RatingToApprove): string =>
                    `/measurements/${row.animalId}/${row.earTag}`,
                testId: 'detail-button',
            },
        ],
    }), [deleteRating, editRating, mortifyAnimal, mortifyInProgress, t]);

    return (
        <ConnectedTable
            tableId="measurements"
            rowDataPageSelector={simpleMeasurementSelector.allRatings}
            emptyTableMessages={{noRows: t('noMeasurements')}}
            highlightOnEdit
            showFulltextFilter
            rowActionsOnRight
            calcRowBackgroundDesign={calcRowBackgroundDesign}
            columns={getColumns(getTypeOptions(t), t, tCommon)}
            onFetchTableData={getMeasurements}
            actionSettings={actionSettings}
            enableTableUserSettings
        />
    );
};

export const MeasurementsTable = memo(MeasurementsTableBase);
