import {TableColumn} from '@fl/cmsch-fe-library';

import {BullView} from 'api/gen/BullView';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    t: TFunction<'bull/bullTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<BullView>> => [
    {
        field: 'name',
        column: tCommon('name'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 132,
        fulltextFilterEnabled: true,
    },
    {
        field: 'lineRegistry',
        column: tCommon('registry'),
        type: 'string',
        sortable: true,
        width: 121,
        fulltextFilterEnabled: true,
    },
    {
        field: 'registrarName',
        column: t('subjectName'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 498,
    },
    {
        field: 'marked',
        column: t('marked'),
        type: 'boolean',
        sortable: true,
        customLabels: {yes: 'T', no: 'X'},
        formatValue: (value: boolean): string => value ? 'T' : 'X',
        width: 95,
    },
    {
        field: 'numOfDaughtersToMeasure',
        column: t('numOfDaughtersToMeasure'),
        type: 'number',
        sortable: true,
        width: 166,
    },
    {
        field: 'numOfMeasuredDaughters',
        column: t('numOfMeasuredDaughtersAbbr'),
        tooltip: t('numOfMeasuredDaughters'),
        type: 'number',
        sortable: true,
        width: 166,
    },
    {
        field: 'numOfMeasuredBreedings',
        column: t('numOfMeasuredBreedingsAbbr'),
        tooltip: t('numOfMeasuredBreedingsTooltip'),
        type: 'number',
        sortable: true,
        width: 166,
    },
    {
        field: 'note',
        column: t('note'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 124,
    },
    {
        field: 'areInvoicingDocumentsCreated',
        column: t('isInvoiced'),
        type: 'boolean',
        sortable: true,
        width: 129,
    },
    {
        field: 'invoicingDate',
        column: t('invoicedAtAbbr'),
        tooltip: t('invoicedAt'),
        type: 'date',
        dateFormat: 'date',
        sortable: true,
        filterTypes: ['null'],
        width: 157,
    },
];
