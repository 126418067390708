import {putAll, showBeError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {breedingAction} from 'app/breeding/model/action';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {AddBreedingToPlanAction, planningAction} from '../action';

function* execute({payload: {breedingId}}: AddBreedingToPlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('updatePlan', true));
    yield* put(breedingAction.addBreedingIdToLoading(breedingId));

    const response = yield* call(Api.addBreedingToPlan, {breedingId});

    yield* put(breedingAction.removeBreedingIdFromLoading(breedingId));
    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('updatePlanTitle')));
    }

    yield* put(planningAction.setBreedinginPlanFinish());
    yield* put(layoutAction.setItemLoading('updatePlan', false));
}

export function* addBreedingToPlanSaga(): SagaIterator {
    yield takeLatestF('planning/ADD_BREEDING_TO_PLAN', execute);
}
