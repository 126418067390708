import {TableColumn} from '@fl/cmsch-fe-library';

import {BreederSuite} from 'api/gen/BreederSuite';
import {TFunction} from 'app/translations';

interface RowWithId {id: string}

export type BreederSuiteRow = BreederSuite & RowWithId;

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    t: TFunction<'breederSuite/BreederSuitesTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<BreederSuite>> => [
    {
        field: 'userCode',
        column: tCommon('userCode'),
        type: 'string',
        align: 'right',
        sortable: true,
        width: 105,
    },
    {
        field: 'ratingDate',
        column: tCommon('date'),
        tooltip: tCommon('ratingDate'),
        type: 'date',
        dateFormat: 'date',
        sortable: true,
        width: 164,
    },
    {
        field: 'breederName',
        column: tCommon('company'),
        type: 'string',
        sortable: true,
        width: 508,
    },
    {
        field: 'stableName',
        column: tCommon('stable'),
        type: 'string',
        sortable: true,
        width: 508,
    },
    {
        field: 'breedingNumber',
        column: tCommon('breeding'),
        tooltip: tCommon('breedingNumber'),
        type: 'string',
        align: 'right',
        sortable: true,
        width: 115,
    },
    {
        field: 'sumOfAnimals',
        column: t('sumOfAnimals'),
        type: 'number',
        align: 'right',
        sortable: true,
        width: 123,
    },
    {
        field: 'lactation',
        column: tCommon('lactation'),
        type: 'number',
        align: 'right',
        sortable: true,
        width: 115,
    },
    {
        field: 'emailSentDate',
        column: t('emailSentDate'),
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
        align: 'right',
        sortable: true,
        width: 164,
    },
];
