import {TableColumn} from '@fl/cmsch-fe-library';

import {Breeder} from 'api/gen/Breeder';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (t: TFunction<'breeder'>, tCommon: TFunction<'common'>): Array<TableColumn<Breeder>> => [
    {
        field: 'name',
        column: t('name'),
        type: 'string',
        sortable: true,
        width: 212,
        fulltextFilterEnabled: true,
    },
    {
        field: 'cin',
        column: t('ico'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 212,
        fulltextFilterEnabled: true,
    },
    {
        field: 'vatNumber',
        column: t('dic'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 212,
    },
    {
        field: 'phone',
        column: t('phone'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 212,
    },
    {
        field: 'email',
        column: tCommon('email'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 212,
    },
];
