import {StyleVariables, Notifications, ComponentsContextProvider} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {I18nextProvider} from 'react-i18next';
import {Provider, useSelector} from 'react-redux';
import {Router} from 'react-router-dom';

import {findTableModuleAndCodeById, getTableConfig, hasServerUserSettings} from 'utils/table-config';
import {simpleTableSelector} from 'utils/tables';

import {injectStore} from './api';
import ErrorBoundary from './error-boundary';
import {MenuAppWrapper} from './menu-app-wrapper';
import {Routes} from './routes';
import {logger} from './sentry-logger';
import {history, store} from './setup';
import {t} from './translations';
import {i18n} from './translations/i18n';
import {UnauthorizedRoutes} from './unauthorized-routes';
import {pathNamesSelector, simpleUserSelector, UserPermissionGuard} from './user';

import 'styles/ant-design.less';
import 'styles/styles.sass';

const primaryColor = '#259b24';
const primaryLightColor = '#cbf3cb';

const styleVariables: StyleVariables = {
    tableSorterHoverBg: '#F1F1F1',
    tableFilterHoverBg: '#F1F1F1',
    tableHeaderFixedBg: '#FDFDFD',
    tableHeaderNameColor: 'rgba(0, 0, 0, 0.85)',
    tableHeaderSorterArrowColor: primaryColor,
    dropdownButtonHoverColor: 'rgba(0, 0, 0, 0.8)',
    dropdownButtonFilteredColor: primaryColor,
    tableHeaderBg: '#FAFAFA',
    primary: primaryColor,
    primaryLight: primaryLightColor,
    secondary: '#a2e9a2',
    successBg: '#CCFABA',
};

const AppWithoutStore: FC = () => {
    const tablesState = useSelector(simpleTableSelector.tables);
    const currentUser = useSelector(simpleUserSelector.currentUser);
    const pathNames = useSelector(pathNamesSelector);

    return (
        <I18nextProvider i18n={i18n}>
            <Router history={history}>
                <ComponentsContextProvider
                    language="CS"
                    t={t}
                    tablesState={tablesState}
                    settingsToLocalStorage
                    getTableConfig={getTableConfig}
                    findTableModuleAndCodeById={findTableModuleAndCodeById}
                    logger={logger}
                    hasServerUserSettings={hasServerUserSettings}
                    styleVariables={styleVariables}
                    pathNames={pathNames}
                    monospaceFont={false}
                    userId={currentUser?.id}
                    resetSelectedRowsOnUnmount
                    noEvenRowBackground
                >
                    <DndProvider backend={HTML5Backend}>
                        <Notifications />
                        <UserPermissionGuard>
                            {!isNull(currentUser)
                                ? (
                                    <MenuAppWrapper currentUser={currentUser}>
                                        <Routes />
                                    </MenuAppWrapper>
                                )
                                : (
                                    <UnauthorizedRoutes />
                                )}
                        </UserPermissionGuard>
                    </DndProvider>
                </ComponentsContextProvider>
            </Router>
        </I18nextProvider>
    );
};

injectStore(store);

const App: FC = () => (
    <Provider store={store}>
        <ErrorBoundary>
            <AppWithoutStore />
        </ErrorBoundary>
    </Provider>
);

export default App;
