import {Options, TableColumn} from '@fl/cmsch-fe-library';

import {PlanningDaughterWithMark} from 'api/gen/PlanningDaughterWithMark';
import {Tenant} from 'api/gen/Tenant';
import {TFunction} from 'app/translations';
import {getBreedOptions} from 'app/utils/get-breed-options';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    tenant: Tenant | undefined,
    districtOptions: Options<string>,
    t: TFunction<'breeding/AnimalsTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<PlanningDaughterWithMark>> => [
    {
        field: 'districtName',
        column: tCommon('district'),
        type: 'reactNode',
        width: 233,
        sortable: true,
        options: districtOptions,
        filterTypes: ['multiSelectString'],
    },
    {
        field: 'breederName',
        column: tCommon('company'),
        type: 'string',
        width: 466,
        sortable: true,
        fulltextFilterEnabled: true,
    },
    {
        field: 'stableName',
        column: tCommon('stable'),
        type: 'string',
        width: 292,
        sortable: true,
        fulltextFilterEnabled: true,
    },
    {
        field: 'shortEarTag',
        column: tCommon('earTag'),
        type: 'string',
        width: 152,
        sortable: true,
        fulltextFilterEnabled: true,
    },
    {
        field: 'codex',
        column: tCommon('codex'),
        type: 'string',
        align: 'right',
        width: 93,
        sortable: true,
    },
    {
        field: 'breedAlpha6',
        column: tCommon('breed'),
        type: 'reactNode',
        filterTypes: ['greaterThanBreed', 'containsString', 'null'],
        options: getBreedOptions(t, tenant),
        width: 110,
        sortable: true,
    },
    {
        field: 'fathersLinReg',
        column: tCommon('fathersLinReg'),
        type: 'string',
        width: 110,
        sortable: true,
    },
    {
        field: 'marked',
        type: 'boolean',
        column: tCommon('marked'),
        tooltip: tCommon('marked'),
        customLabels: {yes: t('markedOptions.T'), no: t('markedOptions.X')},
        formatValue: (value: boolean): string => value ? t('markedOptions.T') : t('markedOptions.X'),
        width: 116,
        sortable: true,
    },
    {
        field: 'daysSinceCalveDate',
        column: tCommon('daysSinceCalveDateAbbr'),
        tooltip: tCommon('daysSinceCalveDate'),
        type: 'number',
        width: 128,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'firstCalveAge',
        column: tCommon('joinedCalveDaysAbbr'),
        tooltip: tCommon('joinedCalveDays'),
        type: 'string',
        width: 110,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'proficiencyTest',
        column: tCommon('proficiencyTestAbbr'),
        tooltip: tCommon('proficiencyTest'),
        type: 'number',
        width: 70,
        sortable: true,
        filterTypes: ['null'],
    },
];
