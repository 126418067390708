// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { RatingToApprove, RatingToApproveSchema } from 'api/gen/RatingToApprove';

const requiredPart = t.interface({
  content: t.union([t.array(RatingToApproveSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelRatingToApproveSchema = excess(requiredPart);

export interface PagedModelRatingToApprove extends t.TypeOf<typeof PagedModelRatingToApproveSchema> {}
