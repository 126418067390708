/* eslint-disable import/no-unused-modules */
import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BullView} from 'api/gen/BullView';
import {InvoicingDocuments} from 'api/gen/InvoicingDocuments';
import {PagedModelBullView} from 'api/gen/PagedModelBullView';
import {PagedModelInvoicingDocuments} from 'api/gen/PagedModelInvoicingDocuments';
import {PagedModelPlanningDaughter} from 'api/gen/PagedModelPlanningDaughter';
import {PagedModelPlanningDaughterWithMark} from 'api/gen/PagedModelPlanningDaughterWithMark';
import {PlanningDaughter} from 'api/gen/PlanningDaughter';
import {PlanningDaughterWithMark} from 'api/gen/PlanningDaughterWithMark';

import {EditBullFormValues} from '../types/edit-bull-form-values';

export const bullAction = {
    getBulls: (tableDataRequest: Opt<TableDataRequest<BullView>>) => ({
        type: 'bull/GET_BULLS',
        payload: {tableDataRequest},
    }) as const,
    getBullsSuccess: (bullsPage: Opt<PagedModelBullView>) => ({
        type: 'bull/GET_BULLS_SUCCESS',
        payload: {bullsPage},
    }) as const,
    getPlannigDaughters: (tableDataRequest: Opt<TableDataRequest<PlanningDaughterWithMark>>, bullId: number) => ({
        type: 'bull/GET_BULL_ANIMALS',
        payload: {tableDataRequest, bullId},
    }) as const,
    getPlannigDaughtersSuccess: (animals: PagedModelPlanningDaughterWithMark) => ({
        type: 'bull/GET_BULL_ANIMALS_SUCCESS',
        payload: {animals},
    }) as const,
    getInvoicesDaughters: (tableDataRequest: Opt<TableDataRequest<PlanningDaughter>>, bullId: number) => ({
        type: 'bull/GET_INVOICES_DAUGHTER',
        payload: {tableDataRequest, bullId},
    }) as const,
    getInvoicesDaughtersSuccess: (animals: PagedModelPlanningDaughter) => ({
        type: 'bull/GET_INVOICES_DAUGHTER_SUCCESS',
        payload: {animals},
    }) as const,
    getInvoices: (tableDataRequest: Opt<TableDataRequest<InvoicingDocuments>>) => ({
        type: 'bull/GET_INVOICES',
        payload: {tableDataRequest},
    }) as const,
    getInvoicesSuccess: (invoicesPage: Opt<PagedModelInvoicingDocuments>) => ({
        type: 'bull/GET_INVOICES_SUCCESS',
        payload: {invoicesPage},
    }) as const,
    editBull: (bull: EditBullFormValues) => ({
        type: 'bull/EDIT_BULL',
        payload: {bull},
    }) as const,
    exportBullDaughters: (bullId: number) => ({
        type: 'bull/EXPORT_BULL_DAUGHTERS',
        payload: {bullId},
    }) as const,
    exportBullMarkedDaughters: (bullId: number) => ({
        type: 'bull/EXPORT_BULL_MARKED_DAUGHTERS',
        payload: {bullId},
    }) as const,
    toggleSelectBulls: (ids: Array<number>) => ({
        type: 'bull/TOGGLE_SELECT_BULLS',
        payload: {ids},
    }) as const,
    createInvoicingDocuments: (bullIds: Array<number>) => ({
        type: 'bull/CREATE_INVOICING_DOCUMENTS',
        payload: {bullIds},
    }) as const,
    getInvoicingDocuments: (bullId: number) => ({
        type: 'bull/GET_INVOICING_DOCUMENTS',
        payload: {bullId},
    }) as const,
    setBullInEdit: (bullId?: number) => ({
        type: 'bull/SET_BULL_IN_EDIT',
        payload: {bullId},
    }) as const,
};

export type GetBullsAction = ReturnType<typeof bullAction.getBulls>;
export type GetBullsSuccessAction = ReturnType<typeof bullAction.getBullsSuccess>;
export type GetPlannigDaughtersAction = ReturnType<typeof bullAction.getPlannigDaughters>;
export type GetInvoicesDaughtersAction = ReturnType<typeof bullAction.getInvoicesDaughters>;
export type GetInvoicesDaughtersSuccessAction = ReturnType<typeof bullAction.getInvoicesDaughtersSuccess>;
export type GetPlannigDaughtersSuccessAction = ReturnType<typeof bullAction.getPlannigDaughtersSuccess>;
export type EditBullAction = ReturnType<typeof bullAction.editBull>;
export type ExportBullDaughtersAction = ReturnType<typeof bullAction.exportBullDaughters>;
export type ExportBullMarkedDaughtersAction = ReturnType<typeof bullAction.exportBullMarkedDaughters>;
export type ToggleSelectBullsAction = ReturnType<typeof bullAction.toggleSelectBulls>;
export type CreateInvoicingDocumentsAction = ReturnType<typeof bullAction.createInvoicingDocuments>;
export type GetInvoicingDocumentsAction = ReturnType<typeof bullAction.getInvoicingDocuments>;
export type SetBullInEditAction = ReturnType<typeof bullAction.setBullInEdit>;
export type GetInvoicesAction = ReturnType<typeof bullAction.getInvoices>;
export type GetInvoicesSuccessAction = ReturnType<typeof bullAction.getInvoicesSuccess>;

export type BullAction =
    | GetBullsAction
    | GetBullsSuccessAction
    | GetInvoicesDaughtersAction
    | GetInvoicesDaughtersSuccessAction
    | GetPlannigDaughtersAction
    | GetPlannigDaughtersSuccessAction
    | EditBullAction
    | ExportBullDaughtersAction
    | ToggleSelectBullsAction
    | CreateInvoicingDocumentsAction
    | GetInvoicingDocumentsAction
    | SetBullInEditAction
    | GetInvoicesAction
    | GetInvoicesSuccessAction
    | ExportBullMarkedDaughtersAction
    ;
