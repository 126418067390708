import React, {Fragment, FC, memo, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {Content, PageHeader, simpleLayoutSelector} from 'common/layout';

import {BullEditModal, BullsTable} from '../components';
import {useGetDataForBulls} from '../hooks';

const BullsBase: FC = _ => {
    const loadingItems = useSelector(simpleLayoutSelector.loadingItems);
    const isAdmin = useSelector(simpleUserSelector.isAdmin);

    const {t} = useOurTranslation('bull/bullTable');
    const {
        bullInEdit,
        bullsPage,
        closeBullEditModal,
        exportBullDaughters,
        getBulls,
        goTo,
        onEditBull,
        onOpenEditClick,
    } = useGetDataForBulls();

    const bullEditModalTitle: Opt<string> = useMemo(() =>
        bullsPage.prop('content').findIn(bull => bullInEdit.contains(bull.id)).prop('lineRegistry')
    , [bullInEdit, bullsPage]);

    return (
        <Fragment>
            <Content>
                <PageHeader title={t('bullList')} />

                <BullsTable
                    isAdmin={isAdmin}
                    loadingItems={loadingItems}
                    goTo={goTo}
                    exportBullDaughters={exportBullDaughters}
                    onOpenEditClick={onOpenEditClick}
                    getBulls={getBulls}
                />
            </Content>

            <BullEditModal
                title={bullEditModalTitle}
                editBull={onEditBull}
                closeModal={closeBullEditModal}
            />
        </Fragment>
    );
};

export const Bulls = memo(BullsBase);
