import {TypedFormSectionInjectedProps} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const ExtremitiesFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="sidePosture"
                label={tCommon('sidePosture')}
                isRequired
            />
            <Fields.NumberInput
                name="hooves"
                label={tCommon('hooves')}
                isRequired
            />
            <Fields.NumberInput
                name="bonesQuality"
                label={tCommon('bonesQuality')}
                isRequired
            />
            <Fields.NumberInput
                name="rearPosture"
                label={tCommon('rearPostureHolstein')}
                isRequired
            />
            <Fields.NumberInput
                name="walkQuality"
                label={tCommon('walkQuality')}
                isRequired
            />
            <Fields.NumberInput
                name="frontFeetOrientation"
                label={tCommon('frontFeetOrientation')}

            />
        </Fragment>
    );
};

export const ExtremitiesFormSection = memo(ExtremitiesFormSectionBase);
