/* eslint-disable import/no-unused-modules */
import {EmailTemplate} from 'api/gen/EmailTemplate';
import {Plan} from 'api/gen/Plan';
import {PlanStatistics} from 'api/gen/PlanStatistics';

import {ExportUserMeasurementsType} from '../types/export-user-measurements-type';
import {PlanEmailFormValues} from '../types/plan-email-form-values';

export const planningAction = {
    resetAdminPlansScreen: () => ({
        type: 'planning/RESET_ADMIN_PLANS_SCREEN',
    }) as const,
    getAdminPlans: () => ({
        type: 'planning/GET_ADMIN_PLANS',
    }) as const,
    getAdminPlansSuccess: (adminPlans: Array<PlanStatistics>) => ({
        type: 'planning/GET_ADMIN_PLANS_SUCCESS',
        payload: {adminPlans},
    }) as const,
    exportUserMeasurements: (exportType: ExportUserMeasurementsType) => ({
        type: 'planning/EXPORT_USER_MEASUREMENTS',
        payload: {exportType},
    }) as const,
    resetPlanScreen: () => ({
        type: 'planning/RESET_PLAN_SCREEN',
    }) as const,
    getPlan: () => ({
        type: 'planning/GET_PLAN',
    }) as const,
    getPlanSuccess: (plan: Plan) => ({
        type: 'planning/GET_PLAN_SUCCESS',
        payload: {plan},
    }) as const,
    getPlanError: () => ({
        type: 'planning/GET_ACTIVE_PLAN_ERROR',
    }) as const,
    deletePlan: () => ({
        type: 'planning/DELETE_PLAN',
    }) as const,
    toggleSelectAnimal: (animalId: number, breederId: number, animalsInCompany: Array<number>) => ({
        type: 'planning/TOGGLE_SELECT_ANIMAL',
        payload: {animalId, breederId, animalsInCompany},
    }) as const,
    addAnimalsToPlan: (animalIds: Array<number>) => ({
        type: 'planning/ADD_ANIMALS_TO_PLAN',
        payload: {animalIds},
    }) as const,
    removeAnimalsFromPlan: (animalIds: Array<number>) => ({
        type: 'planning/REMOVE_ANIMALS_FROM_PLAN',
        payload: {animalIds},
    }) as const,
    addBreedingToPlan: (breedingId: number) => ({
        type: 'planning/ADD_BREEDING_TO_PLAN',
        payload: {breedingId},
    }) as const,
    removeBreedingFromPlan: (breedingId: number) => ({
        type: 'planning/REMOVE_BREEDING_FROM_PLAN',
        payload: {breedingId},
    }) as const,
    setPlanEmailModalStableId: (stableId: number) => ({
        type: 'planning/SET_PLAN_EMAIL_MODAL_STABLE_ID',
        payload: {stableId},
    }) as const,
    setPlanEmailModalBreederId: (breederId: number) => ({
        type: 'planning/SET_PLAN_EMAIL_MODAL_BREEDER_ID',
        payload: {breederId},
    }) as const,
    sendPlanStableEmail: (data: PlanEmailFormValues) => ({
        type: 'planning/SEND_PLAN_STABLE_EMAIL',
        payload: {data},
    }) as const,
    sendPlanBreederEmail: (data: PlanEmailFormValues) => ({
        type: 'planning/SEND_PLAN_BREEDER_EMAIL',
        payload: {data},
    }) as const,
    printPlan: () => ({
        type: 'planning/PRINT_PLAN',
    }) as const,
    removeBreederFromPlan: (animalIds: Array<number>) => ({
        type: 'planning/REMOVE_BREEDER_FROM_PLAN',
        payload: {animalIds},
    }) as const,
    printBreederPlan: (breederId: number) => ({
        type: 'planning/PRINT_BREEDER_PLAN',
        payload: {breederId},
    }) as const,
    printBreederStablePlan: (stableId: number) => ({
        type: 'planning/PRINT_BREEDER_STABLE_PLAN',
        payload: {stableId},
    }) as const,
    getEmailTemplate: () => ({
        type: 'planning/GET_EMAIL_TEMPLATE',
    }) as const,
    getEmailTemplateSuccess: (emailTemplate: EmailTemplate) => ({
        type: 'planning/GET_EMAIL_TEMPLATE_SUCCESS',
        payload: {emailTemplate},
    }) as const,
    resetSelectedAnimalsAndCompanies: () => ({
        type: 'planning/RESET_SELECTED_ANIMALS_AND_COMPANIES',
    }) as const,
    setSelectedAnimalsAsDead: (ids: Array<number>) => ({
        type: 'planning/SET_SELECTED_ANIMALS_AS_DEAD',
        payload: {ids},
    }) as const,
    setBreedingIdsToPlan: (breedingIds: Array<number>) => ({
        type: 'planning/SET_BREEDING_IDS_TO_PLAN',
        payload: {breedingIds},
    }) as const,
    setBreedinginPlanFinish: () => ({
        type: 'planning/SET_BREEDING_IN_PLAN_FINISH',
    }) as const,
};

export type ResetAdminPlansScreenAction = ReturnType<typeof planningAction.resetAdminPlansScreen>;
export type GetAdminPlansAction = ReturnType<typeof planningAction.getAdminPlans>;
export type GetAdminPlansSuccessAction = ReturnType<typeof planningAction.getAdminPlansSuccess>;
export type ExportUserMeasurementsAction = ReturnType<typeof planningAction.exportUserMeasurements>;
export type ResetPlanScreenAction = ReturnType<typeof planningAction.resetPlanScreen>;
export type GetPlanAction = ReturnType<typeof planningAction.getPlan>;
export type GetPlanSuccessAction = ReturnType<typeof planningAction.getPlanSuccess>;
export type GetPlanErrorAction = ReturnType<typeof planningAction.getPlanError>;
export type DeletePlanAction = ReturnType<typeof planningAction.deletePlan>;
export type ToggleSelectAnimalAction = ReturnType<typeof planningAction.toggleSelectAnimal>;
export type AddAnimalsToPlanAction = ReturnType<typeof planningAction.addAnimalsToPlan>;
export type RemoveAnimalsFromPlanAction = ReturnType<typeof planningAction.removeAnimalsFromPlan>;
export type AddBreedingToPlanAction = ReturnType<typeof planningAction.addBreedingToPlan>;
export type SetBreedingInPlanFinishAction = ReturnType<typeof planningAction.setBreedinginPlanFinish>;
export type RemoveBreedingFromPlanAction = ReturnType<typeof planningAction.removeBreedingFromPlan>;
export type SetPlanEmailModalStableIdAction = ReturnType<typeof planningAction.setPlanEmailModalStableId>;
export type SetPlanEmailModalBreederIdAction = ReturnType<typeof planningAction.setPlanEmailModalBreederId>;
export type SendPlanStableEmailAction = ReturnType<typeof planningAction.sendPlanStableEmail>;
export type SendPlanBreederEmailAction = ReturnType<typeof planningAction.sendPlanBreederEmail>;
export type PrintPlanAction = ReturnType<typeof planningAction.printPlan>;
export type RemoveBreederFromPlanAction = ReturnType<typeof planningAction.removeBreederFromPlan>;
export type PrintBreederPlanAction = ReturnType<typeof planningAction.printBreederPlan>;
export type PrintBreederStablePlanAction = ReturnType<typeof planningAction.printBreederStablePlan>;
export type GetEmailTemplateAction = ReturnType<typeof planningAction.getEmailTemplate>;
export type GetEmailTemplateSuccessAction = ReturnType<typeof planningAction.getEmailTemplateSuccess>;
export type ResetSelectedAnimalsAndCompaniesAction = ReturnType<typeof planningAction.resetSelectedAnimalsAndCompanies>;
export type SetSelectedAnimalsAsDeadAction = ReturnType<typeof planningAction.setSelectedAnimalsAsDead>;
export type SetBreedingIdsToPlanAction = ReturnType<typeof planningAction.setBreedingIdsToPlan>;

export type PlanningAction =
    | ResetAdminPlansScreenAction
    | GetAdminPlansAction
    | GetAdminPlansSuccessAction
    | ExportUserMeasurementsAction
    | ResetPlanScreenAction
    | GetPlanAction
    | GetPlanSuccessAction
    | GetPlanErrorAction
    | DeletePlanAction
    | ToggleSelectAnimalAction
    | AddAnimalsToPlanAction
    | RemoveAnimalsFromPlanAction
    | AddBreedingToPlanAction
    | SetBreedingInPlanFinishAction
    | RemoveBreedingFromPlanAction
    | SetPlanEmailModalStableIdAction
    | SetPlanEmailModalBreederIdAction
    | SendPlanStableEmailAction
    | SendPlanBreederEmailAction
    | PrintPlanAction
    | RemoveBreederFromPlanAction
    | PrintBreederPlanAction
    | PrintBreederStablePlanAction
    | GetEmailTemplateAction
    | GetEmailTemplateSuccessAction
    | ResetSelectedAnimalsAndCompaniesAction
    | SetSelectedAnimalsAsDeadAction
    | SetBreedingIdsToPlanAction
;
