import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {EmailAudit} from 'api/gen/EmailAudit';
import {PagedModelEmailAudit} from 'api/gen/PagedModelEmailAudit';

export const emailAction = {
    getSentEmails: (tableDataRequest: Opt<TableDataRequest<EmailAudit>>) => ({
        type: 'email/GET_SENT_EMAILS',
        payload: {tableDataRequest},
    }) as const,
    getSentEmailsSuccess: (sentEmailsPage: Opt<PagedModelEmailAudit>) => ({
        type: 'email/GET_SENT_EMAILS_SUCCESS',
        payload: {sentEmailsPage},
    }) as const,
};

export type GetSentEmailsAction = ReturnType<typeof emailAction.getSentEmails>;
export type GetSentEmailsSuccessAction = ReturnType<typeof emailAction.getSentEmailsSuccess>;

export type EmailAction =
    | GetSentEmailsAction
    | GetSentEmailsSuccessAction
    ;
