import {none, Opt} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {Breeding} from 'api/gen/Breeding';
import {PagedModelBreeder} from 'api/gen/PagedModelBreeder';
import {Stable} from 'api/gen/Stable';
import {StableEmail} from 'api/gen/StableEmail';

export interface BreederState {
    breederPage: Opt<PagedModelBreeder>;
    breeder: Breeder | null;
    stable: Stable | null;
    stables: Array<Stable> | null;
    breedings: Array<Breeding> | null;
    alternativeEmails: Array<StableEmail> | null;
    editedStableId: Opt<number>;
}

export const initialBreederState: BreederState = {
    breederPage: none,
    breeder: null,
    stable: null,
    stables: [],
    breedings: [],
    alternativeEmails: null,
    editedStableId: none,
};
