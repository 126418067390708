import {takeLatestF, putAll, showBeError, downloadFileFromResponse} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {ExportBreederSuitesAction} from '../action';

function* execute({payload: {ids, exportType}}: ExportBreederSuitesAction): SagaIterator {
    const isExcel = exportType === 'EXCEL';
    yield* put(layoutAction.setItemLoading(isExcel ? 'exportBreederSuitesExcel' : 'exportBreederSuitesPdf', true));

    const response = yield* call(
        isExcel ? Api.exportAllBreederSuitesToExcel : Api.exportAllBreederSuitesToPdf,
        {breederSuiteIds: ids},
    );

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('breederSuites/messages')('exportBreederSuitesTitle')));
    }

    yield* put(layoutAction.setItemLoading(isExcel ? 'exportBreederSuitesExcel' : 'exportBreederSuitesPdf', false));
}

export function* exportBreederSuitesSaga(): SagaIterator {
    yield takeLatestF('breederSuite/EXPORT_BREEDER_SUITES', execute);
}
