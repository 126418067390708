/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

type Environment = 'development' | 'test' | 'production';

declare const __API__: string;
declare const __DEV__: boolean;
declare const __DISABLE_IMMUTABILITY_CHECK__: boolean;
declare const __DISABLE_SENTRY__: boolean;
declare const __ENV__: Environment;
declare const __VERSION__: string;
declare const __FE_LIBRARY_VERSION__: string;
declare const __LOCAL__: boolean;

export class Config {
    public static readonly apiUrl: string = __API__;

    public static readonly isDev: boolean = __DEV__;

    public static readonly disableImmutabilityCheck: boolean = __DISABLE_IMMUTABILITY_CHECK__;

    public static readonly disableSentry: boolean = __DISABLE_SENTRY__;

    public static readonly environment: Environment = __ENV__;

    public static readonly isProduction = Config.environment === 'production';

    public static readonly isTest = Config.environment === 'test';

    public static readonly version: string = __VERSION__;

    public static readonly feLibraryVersion: string = __FE_LIBRARY_VERSION__;

    public static readonly local = __LOCAL__;
}
