import {TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {BullView} from 'api/gen/BullView';
import {simpleBullSelector} from 'app/bull/model';
import {useOurTranslation} from 'app/translations';
import {LoadingItemId} from 'common/layout/types/loading-item-id';
import {ConnectedTable} from 'utils/tables';

import {useGetActionSettings} from './action-settings';
import {getColumns} from './columns';

const pathnamesForRefresh: Array<RegExp> = [/^\/bulls\/daughters\/\d+$/];

interface Props {
    isAdmin: boolean;
    loadingItems: Array<LoadingItemId>;
    getBulls(tableDataRequest: Opt<TableDataRequest<BullView>>): void;
    goTo(path: string): void;
    exportBullDaughters(bullId: number): void;
    onOpenEditClick(bullId: number): void;
}

const BullsTableBase: FC<Props> = ({
    isAdmin,
    loadingItems,
    getBulls,
    exportBullDaughters,
    onOpenEditClick,
    goTo,
}) => {
    const {t, tCommon} = useOurTranslation('bull/bullTable');

    const actionSettings = useGetActionSettings({
        exportBullDaughters,
        goTo,
        isAdmin,
        loadingItems,
        onOpenEditClick,
    });

    const columns = useMemo(() => getColumns(t, tCommon), [t, tCommon]);
    const emptyTableMessages = useMemo(() => ({noRows: t('noBullsAvailable')}), [t]);

    return (
        <ConnectedTable
            tableId="bull"
            columns={columns}
            rowDataPageSelector={simpleBullSelector.bullsPage}
            onFetchTableData={getBulls}
            actionSettings={actionSettings}
            emptyTableMessages={emptyTableMessages}
            rowActionsOnRight
            highlightOnEdit
            showFulltextFilter
            pathnamesForRefresh={pathnamesForRefresh}
            enableTableUserSettings
        />
    );
};

export const BullsTable = memo(BullsTableBase);
