import {TableColumn} from '@fl/cmsch-fe-library';

import {InvoicingDocuments} from 'api/gen/InvoicingDocuments';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    t: TFunction<'bull/invoicesTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<InvoicingDocuments>> => [
    {
        field: 'name',
        column: tCommon('name'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 124,
        fulltextFilterEnabled: true,
    },
    {
        field: 'lineRegistry',
        column: tCommon('registry'),
        type: 'string',
        sortable: true,
        width: 114,
        fulltextFilterEnabled: true,
    },
    {
        field: 'registrarName',
        column: t('registrarName'),
        type: 'string',
        sortable: true,
        filterTypes: ['null'],
        width: 465,
        fulltextFilterEnabled: true,
    },
    {
        field: 'registrationDate',
        column: t('registeredAt'),
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
        sortable: true,
        width: 155,
    },
    {
        field: 'categoryId',
        column: t('categoryId'),
        type: 'number',
        filterTypes: ['null'],
        sortable: true,
        width: 155,
    },
    {
        field: 'numOfDaughtersToMeasure',
        column: t('numOfDaughtersToMeasure'),
        type: 'number',
        sortable: true,
        width: 155,
    },
    {
        field: 'numOfMeasuredDaughters',
        column: t('numOfMeasuredDaughtersAbbr'),
        tooltip: t('numOfMeasuredDaughters'),
        type: 'number',
        sortable: true,
        width: 155,
    },
    {
        field: 'numOfMeasuredBreedings',
        column: t('numOfMeasuredBreedingsAbbr'),
        tooltip: t('numOfMeasuredBreedings'),
        type: 'number',
        sortable: true,
        width: 155,
    },
    {
        field: 'areInvoicingDocumentsCreated',
        column: t('isInvoiced'),
        type: 'boolean',
        sortable: true,
        width: 121,
    },
    {
        field: 'invoicingDate',
        column: t('invoicedAtAbbr'),
        tooltip: t('invoicedAt'),
        type: 'date',
        dateFormat: 'date',
        sortable: true,
        filterTypes: ['null'],
        width: 146,
    },
];
