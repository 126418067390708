import {TableDataRequest, TableLegend} from '@fl/cmsch-fe-library';
import React, {FC, Fragment, memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Opt} from 'ts-opt';

import {PlanningDaughter} from 'api/gen/PlanningDaughter';
import {bullAction, simpleBullSelector} from 'app/bull/model';
import {ConnectedTable} from 'utils/tables';

import {calcRowBackgroundDesign, getTableAnnotations, useDaughterPrepare} from '../../hooks/use-daughter-prepare';
import {DaughtersProps} from '../../types/daughters-props';
import {getColumns} from './columns';

const pathnamesForRefresh: Array<RegExp> = [/^\/invoicing-documents\/daughters\/.+/];
// const pathnamesForRefresh: Array<RegExp> = [/^\/daughters\//];
// const pathnamesForRefresh: Array<RegExp> = [/^\/invoicing-documents\//];

const InvoicesDaughtersTableBase: FC<DaughtersProps> = ({districts}) => {
    const {
        t,
        tCommon,
        bullId,
        breedTranslation,
        districtOptions,
        tenant,
    } = useDaughterPrepare(districts);
    const dispatch = useDispatch();
    const getDaughters = useCallback((tableDataRequest: Opt<TableDataRequest<PlanningDaughter>>) => {
        dispatch(bullAction.getInvoicesDaughters(tableDataRequest, bullId));
    }, [bullId, dispatch]);

    return (
        <Fragment>
            <ConnectedTable
                tableId="invoicesDaughters"
                rowDataPageSelector={simpleBullSelector.invoicesDaughters}
                pathnamesForRefresh={pathnamesForRefresh}
                onFetchTableData={getDaughters}
                columns={getColumns(tenant, districtOptions, breedTranslation, tCommon)}
                emptyTableMessages={{noRows: t('noDaughters')}}
                calcRowBackgroundDesign={calcRowBackgroundDesign}
                showFulltextFilter
                enableTableUserSettings
            />
            <div className="mt-2">
                <TableLegend annotations={getTableAnnotations(t)} />
            </div>
        </Fragment>
    );
};

export const InvoicesDaughtersTable = memo(InvoicesDaughtersTableBase);
