// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { InvoicingDocuments, InvoicingDocumentsSchema } from 'api/gen/InvoicingDocuments';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';

const requiredPart = t.interface({
  content: t.union([t.array(InvoicingDocumentsSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelInvoicingDocumentsSchema = excess(requiredPart);

export interface PagedModelInvoicingDocuments extends t.TypeOf<typeof PagedModelInvoicingDocumentsSchema> {}
