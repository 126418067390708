import {ActionSettings, TableDataRequest, TableLegend, TableRowBackground} from '@fl/cmsch-fe-library';
import React, {useCallback, useMemo, FC, memo, Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {District} from 'api/gen/District';
import {PlanningBreeding} from 'api/gen/PlanningBreeding';
import {simpleBreedingSelector} from 'app/breeding/model';
import {getDistrictOptions} from 'app/district';
import {useOurTranslation} from 'app/translations';
import {ConnectedTable} from 'utils/tables';

import {isBreedingOverCalveDaysWarningThreshold} from '../../utils/is-over-calve-days-warning-threshold';
import {getColumns} from './columns';

interface Props {
    districts: Array<District> | null;
    selectedBreedings: Array<number>;
    getBreedings(tableDataRequest: Opt<TableDataRequest<PlanningBreeding>>): void;
    onGoTo(path: string): void;
    onToggleSelectedBreedings(stableIds: Array<number>): void;
}

const pathnamesForRefresh: Array<RegExp> = [/^\/breedings\/\d+$/];

const BreedingTableBase: FC<Props> = ({
    districts,
    selectedBreedings,
    getBreedings,
    onGoTo,
    onToggleSelectedBreedings,
}) => {
    const {t, tCommon} = useOurTranslation('breeding/BreedingTable');

    const goToDetail = useCallback((breedingId: number) => {
        onGoTo(`/breedings/${breedingId}`);
    }, [onGoTo]);

    const disabledCheckboxes = useSelector(simpleBreedingSelector.breedingFetchingIds);

    const isCheckboxDisabled = useCallback((rowId: number): boolean =>
        disabledCheckboxes.includes(Number(rowId)),
    [disabledCheckboxes]);

    const calcRowBackgroundDesign = useCallback((
        row: PlanningBreeding,
        _index: number,
    ): TableRowBackground | undefined =>
        isBreedingOverCalveDaysWarningThreshold(row) ? 'dangerous' : undefined, []);

    const actionSettings = useMemo((): ActionSettings<PlanningBreeding> => ({
        selectedIds: selectedBreedings,
        hideSelectAll: true,
        onSelectedIdsChange: onToggleSelectedBreedings,
        isSelectDisabled: isCheckboxDisabled,
        extraActions: [
            {
                id: 'detail',
                icon: 'zoomInOutlined',
                title: tCommon('detail'),
                callback: goToDetail,
                testId: 'detail-button',
            },
        ],
    }), [selectedBreedings, goToDetail, tCommon, onToggleSelectedBreedings, isCheckboxDisabled]);

    const districtOptions = useMemo(() => getDistrictOptions(districts), [districts]);

    return (
        <Fragment>
            <ConnectedTable
                tableId="breeding"
                columns={getColumns(districtOptions, t, tCommon)}
                rowDataPageSelector={simpleBreedingSelector.breedingPage}
                onFetchTableData={getBreedings}
                actionSettings={actionSettings}
                calcRowBackgroundDesign={calcRowBackgroundDesign}
                emptyTableMessages={{noRows: t('noRows'), noRowsForFilters: t('noRowsForFilters')}}
                rowActionsOnRight
                showFulltextFilter
                pathnamesForRefresh={pathnamesForRefresh}
                enableTableUserSettings
            />
            <div className="mt-2">
                <TableLegend annotations={[{color: 'dangerous', label: t('dangerousAnnotation')}]} />
            </div>
        </Fragment>
    );
};

export const BreedingTable = memo(BreedingTableBase);
