import {none, Opt} from 'ts-opt';

import {PagedModelBullView} from 'api/gen/PagedModelBullView';
import {PagedModelInvoicingDocuments} from 'api/gen/PagedModelInvoicingDocuments';
import {PagedModelPlanningDaughter} from 'api/gen/PagedModelPlanningDaughter';
import {PagedModelPlanningDaughterWithMark} from 'api/gen/PagedModelPlanningDaughterWithMark';

export interface BullState {
    bullsPage: Opt<PagedModelBullView>;
    daughters: Opt<PagedModelPlanningDaughterWithMark>;
    invoicesDaughters: Opt<PagedModelPlanningDaughter>;
    selectedBullsIds: Array<number>;
    bullInEdit: Opt<number>;
    invoicesPage: Opt<PagedModelInvoicingDocuments>;
}

export const initialBullState: BullState = {
    bullsPage: none,
    daughters: none,
    invoicesDaughters: none,
    selectedBullsIds: [],
    bullInEdit: none,
    invoicesPage: none,
};
