/* eslint-disable import/no-unused-modules */
import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {BasicBreeding} from 'api/gen/BasicBreeding';
import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagedModelPlanningAnimal} from 'api/gen/PagedModelPlanningAnimal';
import {PagedModelPlanningBreeding} from 'api/gen/PagedModelPlanningBreeding';
import {PlanningAnimal} from 'api/gen/PlanningAnimal';
import {PlanningBreeding} from 'api/gen/PlanningBreeding';

export const breedingAction = {
    getBreedings: (tableDataRequest: Opt<TableDataRequest<PlanningBreeding>>) => ({
        type: 'breeding/GET_BREEDINGS',
        payload: {tableDataRequest},
    }) as const,
    getBreedingsSuccess: (breedingPage: Opt<PagedModelPlanningBreeding>) => ({
        type: 'breeding/GET_BREEDINGS_SUCCESS',
        payload: {breedingPage},
    }) as const,
    resetBreedingDetailScreen: () => ({
        type: 'breeding/RESET_BREEDING_DETAIL_SCREEN',
    }) as const,
    getBreeding: (breedingId: number) => ({
        type: 'breeding/GET_BREEDING',
        payload: {breedingId},
    }) as const,
    getBreedingResult: (breeding: BreedingDetails) => ({
        type: 'breeding/GET_BREEDING_RESULT',
        payload: {breeding},
    }) as const,
    getAnimalsInBreeding: (breedingId: number, tableDataRequest: Opt<TableDataRequest<PlanningAnimal>>) => ({
        type: 'breeding/GET_ANIMALS_IN_BREEDING',
        payload: {breedingId, tableDataRequest},
    }) as const,
    getAnimalsInBreedingSuccess: (animalsInBreedingPage: Opt<PagedModelPlanningAnimal>) => ({
        type: 'breeding/GET_ANIMALS_IN_BREEDING_SUCCESS',
        payload: {animalsInBreedingPage},
    }) as const,
    getBreedingNumbers: () => ({
        type: 'breeding/GET_BREEDING_NUMBERS',
    }) as const,
    getBreedingNumbersResult: (breedingNumbers: Array<BasicBreeding>) => ({
        type: 'breeding/GET_BREEDING_NUMBERS_RESULT',
        payload: {breedingNumbers},
    }) as const,
    toggleSelectedAnimalInBreeding: (animalId: number) => ({
        type: 'breeding/TOGGLE_SELECTED_ANIMAL_IN_BREEDING',
        payload: {animalId},
    }) as const,
    setSelectedAnimalsInBreeding: (animalIds: Array<number>) => ({
        type: 'breeding/SET_SELECTED_ANIMALS_IN_BREEDING',
        payload: {animalIds},
    }) as const,
    addIdsToLoading: (animalIds: Array<number>) => ({
        type: 'breeding/ADD_IDS_TO_LOADING',
        payload: {animalIds},
    }) as const,
    removeIdsFromLoading: (animalIds: Array<number>) => ({
        type: 'breeding/REMOVE_IDS_FROM_LOADING',
        payload: {animalIds},
    }) as const,
    addBreedingIdToLoading: (breedingId: number) => ({
        type: 'breeding/ADD_BREEDING_ID_TO_LOADING',
        payload: {breedingId},
    }) as const,
    removeBreedingIdFromLoading: (breedingId: number) => ({
        type: 'breeding/REMOVE_BREEDING_ID_FROM_LOADING',
        payload: {breedingId},
    }) as const,
    cleanIdsInLoading: () => ({
        type: 'breeding/CLEAN_IDS_IN_LOADING',
    }) as const,
    cleanBreedingIdsInLoading: () => ({
        type: 'breeding/CLEAN_BREEDING_IDS_IN_LOADING',
    }) as const,
};

export type GetBreedingsAction = ReturnType<typeof breedingAction.getBreedings>;
export type AddIdsToLoadingAction = ReturnType<typeof breedingAction.addIdsToLoading>;
export type CleanIdsInLoadingAction = ReturnType<typeof breedingAction.cleanIdsInLoading>;
export type RemoveIdsFromLoadingAction = ReturnType<typeof breedingAction.removeIdsFromLoading>;
export type GetBreedingsSuccessAction = ReturnType<typeof breedingAction.getBreedingsSuccess>;
export type ResetBreedingDetailScreenAction = ReturnType<typeof breedingAction.resetBreedingDetailScreen>;
export type GetBreedingAction = ReturnType<typeof breedingAction.getBreeding>;
export type GetBreedingResultAction = ReturnType<typeof breedingAction.getBreedingResult>;
export type GetAnimalsInBreedingAction = ReturnType<typeof breedingAction.getAnimalsInBreeding>;
export type GetAnimalsInBreedingSuccessAction = ReturnType<typeof breedingAction.getAnimalsInBreedingSuccess>;
export type GetBreedingNumbersAction = ReturnType<typeof breedingAction.getBreedingNumbers>;
export type GetBreedingNumbersResultAction = ReturnType<typeof breedingAction.getBreedingNumbersResult>;
export type ToggleSelectedAnimalInBreedingAction = ReturnType<typeof breedingAction.toggleSelectedAnimalInBreeding>;
export type SetSelectedAnimalsInBreedingAction = ReturnType<typeof breedingAction.setSelectedAnimalsInBreeding>;
export type AddBreedingIdToLoadingAction = ReturnType<typeof breedingAction.addBreedingIdToLoading>;
export type CleanBreedingIdInLoadingAction = ReturnType<typeof breedingAction.cleanBreedingIdsInLoading>;
export type RemoveBreedingIdFromLoadingAction = ReturnType<typeof breedingAction.removeBreedingIdFromLoading>;

export type BreedingAction =
    | GetBreedingsAction
    | AddIdsToLoadingAction
    | CleanIdsInLoadingAction
    | RemoveIdsFromLoadingAction
    | GetBreedingsSuccessAction
    | ResetBreedingDetailScreenAction
    | GetBreedingAction
    | GetBreedingResultAction
    | GetAnimalsInBreedingAction
    | GetAnimalsInBreedingSuccessAction
    | GetBreedingNumbersAction
    | GetBreedingNumbersResultAction
    | ToggleSelectedAnimalInBreedingAction
    | SetSelectedAnimalsInBreedingAction
    | RemoveBreedingIdFromLoadingAction
    | AddBreedingIdToLoadingAction
    | CleanBreedingIdInLoadingAction
    ;
