import {AuthData, getAuthData, Requester, ResponseError} from '@fl/cmsch-fe-library';
import {Store} from 'redux';

import {Config} from './config';
import {logger} from './sentry-logger';
import {State} from './setup/state';
import {userAction} from './user/model/action';

let store: Store<State>;
export const injectStore = (_store: Store<State>): void => {
    store = _store;
};

const reportValidationError = (error: Error): void => {
    logger.logError(error);
};

const onServerError = (error: ResponseError): void => {
    logger.logErrorAndConsole(error);
    store.dispatch(userAction.setIsServerError(true));
};

const redirectToLogin = (): void => {
    if (window.location.pathname !== '/login') {
        window.location.href = '/logout';
    }
};

// TODO: remove during oauth migration

// Manual encode via unescape URI fixes axios 0.21.1 problem with encoding non-ASCII characters via 'btoa'
// https://developer.mozilla.org/en-US/docs/Glossary/Base64
const stringifyAuthData = (authData: AuthData): string =>
    btoa(unescape(encodeURIComponent(`${authData.username}:${authData.password}`)));

const authDataToToken = (authData: AuthData): string => `Basic ${stringifyAuthData(authData)}`;

const buildAuthToken = (): string | undefined => getAuthData().map(authDataToToken).orUndef();

// eslint-disable-next-line import/no-unused-modules
export const apiRequester = new Requester({
    baseUrl: Config.apiUrl,
    devMode: Config.isDev,
    withCredentials: Config.isDev,
    timeout: 60000,
    onValidationError: reportValidationError,
    onServerError,
    authType: 'basic',
    getAuthToken: buildAuthToken,
    onUnauthorized: redirectToLogin,
});
