import {Nullable} from '@fl/cmsch-fe-library';
import {filter, map} from 'lodash/fp';
import {createSelector} from 'reselect';
import {Opt, pipe} from 'ts-opt';

import {BreedingDetails} from 'api/gen/BreedingDetails';
import {PagedModelPlanningAnimal} from 'api/gen/PagedModelPlanningAnimal';
import {PagedModelPlanningBreeding} from 'api/gen/PagedModelPlanningBreeding';
import {PlanningBreeding} from 'api/gen/PlanningBreeding';
import {State} from 'app/setup';

export const simpleBreedingSelector = {
    animalsInBreedingPage: ({breeding}: State): Opt<PagedModelPlanningAnimal> => breeding.animalsInBreedingPage,
    breedingPage: ({breeding}: State): Opt<PagedModelPlanningBreeding> => breeding.breedingPage,
    breeding: ({breeding}: State): Nullable<BreedingDetails> => breeding.breeding,
    fetchingIds: ({breeding}: State): Array<number> => breeding.fetchingIds,
    breedingFetchingIds: ({breeding}: State): Array<number> => breeding.breedingFetchingIds,
};

export const breedingIdsInPlanningSelector = createSelector(
    simpleBreedingSelector.breedingPage,
    (breedingPage: Opt<PagedModelPlanningBreeding>): Array<number> => pipe(
        breedingPage.prop('content').orElse([]),
        filter<PlanningBreeding>(breeding => breeding.allAnimalsPlanned),
        map<PlanningBreeding, number>(x => x.id),
    ),
);
