// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { MeasurementView, MeasurementViewSchema } from 'api/gen/MeasurementView';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PageMetadata, PageMetadataSchema } from 'api/gen/PageMetadata';

const requiredPart = t.interface({
  content: t.union([t.array(MeasurementViewSchema), t.null]),
  page: t.union([PageMetadataSchema, t.null]),
});

export const PagedModelAbstractMeasurementViewSchema = excess(requiredPart);

export interface PagedModelAbstractMeasurementView extends t.TypeOf<typeof PagedModelAbstractMeasurementViewSchema> {}
