import {takeLatestF} from '@fl/cmsch-fe-library';
import {difference} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {put, select, take} from 'typed-redux-saga';

import {breedingAction, breedingIdsInPlanningSelector} from 'app/breeding';

import {SetBreedingIdsToPlanAction, planningAction} from '../action';

function* execute({payload: {breedingIds}}: SetBreedingIdsToPlanAction): SagaIterator {
    const breedingIdsInPlan = yield * select(breedingIdsInPlanningSelector);

    const breedingIdsToAdd = opt(difference(breedingIds)(breedingIdsInPlan));
    const breedingIdsToRemove = opt(difference(breedingIdsInPlan)(breedingIds));

    const breedingIdToAdd = breedingIdsToAdd.headIn();
    const breedingIdToRemove = breedingIdsToRemove.headIn();

    if (breedingIdToAdd.isFull) {
        yield* put(planningAction.addBreedingToPlan(
            breedingIdToAdd.orCrash('breedingId for adding to plan is not number'),
        ));
    }

    if (breedingIdToRemove.isFull) {
        yield* put(planningAction.removeBreedingFromPlan(
            breedingIdToRemove.orCrash('breedingId for remove from plan is not number'),
        ));
    }

    if (breedingIdToAdd.isFull || breedingIdToRemove.isFull) {
        yield* take('planning/SET_BREEDING_IN_PLAN_FINISH');
        yield* put(planningAction.getPlan());
        yield* put(breedingAction.getBreedings(opt({action: 'refresh'})));
    }
}

export function* setBreedingIdsToPlanSaga(): SagaIterator {
    yield takeLatestF('planning/SET_BREEDING_IDS_TO_PLAN', execute);
}
