import {Options} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {AnimalWithBreedingSearch} from 'api/gen/AnimalWithBreedingSearch';
import {MeasurementDetail} from 'api/gen/MeasurementDetail';
import {MeasurementSearch} from 'api/gen/MeasurementSearch';
import {State} from 'app/setup';

export const simpleRepairSelector = {
    measurementSearch: ({repair}: State): Opt<Array<MeasurementSearch>> => repair.measurementSearch,
    measurementDetail: ({repair}: State): Opt<MeasurementDetail> => repair.measurementDetail,
    foundAnimals: ({repair}: State): Options<string> =>
        repair.foundAnimals.orElse([]).map(animal => ({value: animal.earTag, label: animal.earTag})),
    foundAnimal: (earTag: string) => ({repair}: State): Opt<AnimalWithBreedingSearch> =>
        repair.foundAnimals.findIn(y => y.earTag === earTag),
};
