import {TableDataRequest} from '@fl/cmsch-fe-library';
import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt, opt} from 'ts-opt';

import {BullView} from 'api/gen/BullView';
import {PagedModelBullView} from 'api/gen/PagedModelBullView';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {bullAction, simpleBullSelector} from '../model';
import {EditBullFormValues} from '../types/edit-bull-form-values';

interface UseGetDataForBulls {
    bullsPage: Opt<PagedModelBullView>;
    bullInEdit: Opt<number>;
    goTo(path: string): void;
    exportBullDaughters(bullId: number): void;
    getBulls(tableDataRequest: Opt<TableDataRequest<BullView>>): void;
    closeBullEditModal(): void;
    onOpenEditClick(bullId: number): void;
    onEditBull(bull: EditBullFormValues): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetDataForBulls = (): UseGetDataForBulls => {
    const bullsPage = useSelector(simpleBullSelector.bullsPage);
    const bullInEdit = useSelector(simpleBullSelector.bullInEdit);

    const dispatch = useDispatch();

    const goTo = useCallback((path: string) => {
        dispatch(routerActions.push(path));
    }, [dispatch]);

    const exportBullDaughters = useCallback((bullId: number) => {
        dispatch(bullAction.exportBullDaughters(bullId));
    }, [dispatch]);

    const openBullEditModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('bullEdit', true));
    }, [dispatch]);

    const closeBullEditModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('bullEdit', false));
        dispatch(bullAction.setBullInEdit());
    }, [dispatch]);

    const setBullInEdit = useCallback((bullId: number) => {
        dispatch(bullAction.setBullInEdit(bullId));
    }, [dispatch]);

    const setBullFormValues = useCallback((bull: EditBullFormValues) => {
        dispatch(formHelpers.initialize('bullForm', bull));
    }, [dispatch]);

    const getBulls = useCallback((tableDataRequest: Opt<TableDataRequest<BullView>>) => {
        dispatch(bullAction.getBulls(tableDataRequest));
    }, [dispatch]);

    const onOpenEditClick = useCallback((bullId: number) => {
        const bull = opt(bullsPage.prop('content').orElse([]).find(x => bullId === x.id));

        setBullInEdit(bullId);
        openBullEditModal();
        bull.onSome(x => setBullFormValues({
            marked: x.marked,
            name: x.name,
            note: x.note,
        }));
    }, [bullsPage, openBullEditModal, setBullFormValues, setBullInEdit]);

    const onEditBull = useCallback((bull: EditBullFormValues) => {
        dispatch(bullAction.editBull(bull));
    }, [dispatch]);

    return ({
        bullInEdit,
        bullsPage,
        closeBullEditModal,
        exportBullDaughters,
        getBulls,
        goTo,
        onEditBull,
        onOpenEditClick,
    });
};
