import {deleteAuthData, showError, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {logger} from 'app/sentry-logger';
import {t} from 'app/translations';

import {LogOutUserAction, userAction} from '../action';
import {goToLoginScreen} from './go-to-login';

const translate = t('user/messages');

function* execute(_: LogOutUserAction): SagaIterator {
    deleteAuthData();
    logger.setAnonymousUser();

    const response = yield* call(Api.logout);

    if (response.isSuccess) {
        yield* put(userAction.logOutUserSuccess());
        yield* put(userAction.initializeFinish());
    } else {
        yield* put(showError(translate('logOutTitle'), translate('logOutMessage')));
    }

    yield* call(goToLoginScreen);
}

export function* logOutUserSaga(): SagaIterator {
    yield takeLatestF('user/LOG_OUT_USER', execute);
}
