import {Nullable} from '@fl/cmsch-fe-library';
import {createSelector, Selector} from 'reselect';
import {Opt, optEmptyArray} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {Breeding} from 'api/gen/Breeding';
import {PagedModelBreeder} from 'api/gen/PagedModelBreeder';
import {Stable} from 'api/gen/Stable';
import {StableEmail} from 'api/gen/StableEmail';
import {State} from 'app/setup';

export const simpleBreederSelector = {
    breeder: ({breeder}: State): Nullable<Breeder> => breeder.breeder,
    breederPage: ({breeder}: State): Opt<PagedModelBreeder> => breeder.breederPage,
    breedings: ({breeder}: State): Nullable<Array<Breeding>> => breeder.breedings,
    stable: ({breeder}: State): Nullable<Stable> => breeder.stable,
    stables: ({breeder}: State): Nullable<Array<Stable>> => breeder.stables,
    editedStableId: ({breeder}: State): Opt<number> => breeder.editedStableId,
    alternativeEmails: ({breeder}: State): Nullable<Array<StableEmail>> => breeder.alternativeEmails,
};

export const alternativeEmailsSelector = createSelector<[Selector<State, Array<StableEmail> | null>], Array<string>>(
    simpleBreederSelector.alternativeEmails,
    (alternativeEmails: Nullable<Array<StableEmail>>): Array<string> =>
        optEmptyArray(alternativeEmails).mapIn(x => x.email).orElse([]),
);
