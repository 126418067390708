import {Opt} from 'ts-opt';

import {PagedModelBullView} from 'api/gen/PagedModelBullView';
import {PagedModelInvoicingDocuments} from 'api/gen/PagedModelInvoicingDocuments';
import {PagedModelPlanningDaughter} from 'api/gen/PagedModelPlanningDaughter';
import {PagedModelPlanningDaughterWithMark} from 'api/gen/PagedModelPlanningDaughterWithMark';
import {State} from 'app/setup';

export const simpleBullSelector = {
    bullInEdit: ({bull}: State): Opt<number> => bull.bullInEdit,
    bullsPage: ({bull}: State): Opt<PagedModelBullView> => bull.bullsPage,
    invoicesPage: ({bull}: State): Opt<PagedModelInvoicingDocuments> => bull.invoicesPage,
    selectedBullsIds: ({bull}: State): Array<number> => bull.selectedBullsIds,
    daughters: ({bull}: State): Opt<PagedModelPlanningDaughterWithMark> => bull.daughters,
    invoicesDaughters: ({bull}: State): Opt<PagedModelPlanningDaughter> => bull.invoicesDaughters,
};
