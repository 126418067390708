import {extractFormErrorsFromResponse, putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {BreederSuiteEmail} from 'api/gen/BreederSuiteEmail';
import {sendToBreedersFormName} from 'app/breeder-suite/components/send-to-breeders-form/form-values';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {breederSuiteAction, SendMailToBreedersAction} from '../action';
import {simpleBreederSuiteSelector} from '../selector';

function* execute({payload: {data}}: SendMailToBreedersAction): SagaIterator {
    const {emails, content, emailsToSendCopyTo, mergeStables} = data;
    yield* put(formHelpers.startSubmit(sendToBreedersFormName));
    yield* put(layoutAction.setItemLoading('sendMailToBreeders', true));
    const selectedBreederSuitesIds = yield* select(simpleBreederSuiteSelector.selectedBreederSuitesIds);
    const requestData: BreederSuiteEmail = {
        emails: opt(emails).orCrash('missing emails'),
        content: opt(content).orCrash('missing content'),
        emailsToSendCopyTo,
    };

    const response = yield* call(
        mergeStables ? Api.sendEmailToBreedersWithMergedStables : Api.sendEmailToBreedersWithSeparateStables,
        requestData,
        {breederSuiteIds: selectedBreederSuitesIds},
    );

    if (response.isSuccess) {
        yield* put(layoutAction.setModalVisibility('sendToBreeders', false));
        yield* put(formHelpers.reset(sendToBreedersFormName));
        yield* put(showSuccess(
            t('breederSuites/SendToBreedersForm')('title'),
            t('breederSuites/messages')('sendEmailSuccess'),
        ));
        yield* put(breederSuiteAction.getBreederSuites(opt({action: 'refresh'})));
    } else {
        yield putAll(showBeError(response, t('breederSuites/SendToBreedersForm')('title')));
        yield* put(formHelpers.stopSubmit(sendToBreedersFormName, extractFormErrorsFromResponse(response)));
    }

    yield* put(layoutAction.setItemLoading('sendMailToBreeders', false));
    yield* put(formHelpers.stopSubmit(sendToBreedersFormName, {}));
}

export function* sendMailToBreedersSaga(): SagaIterator {
    yield takeLatestF('breederSuite/SEND_MAIL_TO_BREEDERS', execute);
}
