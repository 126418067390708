import {sum} from 'lodash/fp';
import {optEmptyArray} from 'ts-opt';

import {Plan} from 'api/gen/Plan';

export const getAnimalsCount = (plan: Plan): number =>
    optEmptyArray(plan.breeders)
        .flatMapIn(breeder => breeder.stables)
        .mapIn(stable => stable.animals.length)
        .fold(sum, 0);
